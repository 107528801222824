import React from 'react'
import Footer from "../components/Footer";
import Section1TraderDash from '../components/TraderDash/Section1TraderDash';
import Navbar from '../components/Navbar';



const TraderDash = () => {
  return (
    <div style={{ height: "100vh" }}>
    <Navbar />
    <Section1TraderDash/>
    <Footer/>
   
 </div>
  )
}

export default TraderDash