import React from 'react'
import Footer from "../components/Footer";
import Navbar from '../components/Navbar';
import Section1SignUp from '../components/SignUp/Section1SignUp';

const SignUp = () => {
  return (
    <div style={{ height: "100vh" }}>
       <Navbar/>
       <Section1SignUp/>
       <Footer/>
      
    </div>
  )
}

export default SignUp