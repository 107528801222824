import React from 'react'
import Footer from "../components/Footer";
import Navbar from '../components/Navbar';
import Section1SignIn from '../components/SignIn/Section1SignIn';

const SignIn = () => {
  return (
    <div style={{ height: "100vh" }}>
       <Navbar/>
       <Section1SignIn/>
       <Footer/>
      
    </div>
  )
}

export default SignIn