import React, { useEffect, useState } from "react";
import "../css/Navbar.css";
import Logo from "../Images/navbar-logo.png";
import { Link, NavLink } from "react-router-dom";
import Cookies from 'js-cookie'
import axios from 'axios'
import { CgProfile } from "react-icons/cg";
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = ({logoToggle}) => {

  const BACKEND = process.env.REACT_APP_BACKEND
  const typeUser = Cookies.get('typeUser')

  const navigate = useNavigate();
  const [nameProfile,setNameProfile] = useState('')


  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);

  useEffect(() => {
      const handleScroll = () => {
          const currentScrollPosition = window.scrollY;

          if (currentScrollPosition > lastScrollPosition) {
              setIsHidden(true);
          } else {
              setIsHidden(false);
          }
          setLastScrollPosition(currentScrollPosition);
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, [lastScrollPosition]);

  
  useEffect(()=>{
   console.log(Cookies.get('Authorization'),"ppp")
    if(Cookies.get('Authorization') !== undefined){
      setNameProfile(Cookies.get('nameUser'))
      // axios.get(`${BACKEND}navprofile`,{
      //   headers: {
      //     Authorization: Cookies.get('Authorization'),
      //     typeUser:Cookies.get('typeUser'),
      //     'Content-type': 'application/json',
      //       'Accept': 'application/json',
      //       'Access-Control-Allow-Origin': '*'
      //   },
      // })
      // .then(res => {console.log(res.data);setNameProfile(res.data.user);})
      // .catch(err => {console.log(err)})
    }

  },[])

  const [menuOpen, setMenuOpen] = useState(false);
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };
  window.addEventListener("scroll", changeColor);


  const logout=()=>{
    Cookies.remove('Authorization')
    Cookies.remove('typeUser')
    Cookies.remove('typeName')
     toast.success("Logout Successful")
     setTimeout(() => { navigate('/') }, 3000)
     
  }

  return (
   
    <nav
      // className={
      //   color ? `navbar navbar-bg  ${menuOpen ? "open" : ""}` : `navbar  ${menuOpen ? "open" : ""}`
      // }
      // ${isHidden ? 'd-none' : ''}
      className={
        isHidden ?'hidden': color ? `navbar navbar-bg   ${menuOpen ? "open" : ""}` : `navbar ${menuOpen ? "open" : ""}`

      }
      style={{ position: 'fixed'}}
    >
     
        <div className="logo">
        <Link to="/">
          <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent", marginLeft: "40px" }}
          />
        </Link>
      </div>
     
     
      
      <ul className={ ` ms-auto menu ${menuOpen ? "open" : ""}`}>
        {/* <li>
          <div>
            <NavLink
              to="about"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
              About ATS
            </NavLink>
          </div>
        </li> */}
        {/* <li>How It Works</li> */}
         <li className='ms-1'>
          <div>
            <NavLink
              to="/stratergy"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
              Studio
            </NavLink>
          </div>
        </li>
        <li className='ms-1'>
          <div>
            <NavLink
              to="/optimizer"
              // style={({ isActive }) => ({
              //   color: isActive ? "lightgreen" : "black navbar-bg navbar",
              //   textDecoration: "none",
              // })}
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
              Optimizer
            </NavLink>
          </div>
        </li>
        <li className='ms-1'>
          <div>
            <NavLink
              to="/community"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
              Community Builder
            </NavLink>
          </div>
        </li>
        {
          typeUser == 'user'?
        <li className='ms-1'>
          <div>
            <NavLink
              to="/userDash"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
             User Dashboard
            </NavLink>
          </div>
        </li>
:<></>}
{
  typeUser == 'trader'?
        <li className='ms-1'>
          <div>
            <NavLink
              to="/traderDash"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
              Trader Dashboard
            </NavLink>
          </div>
        </li>
        :<></>}
        {
          nameProfile == '' ?
          <>
          <li className='ms-1'>
          <div>
            <NavLink
              to="/signin"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
             Sign In
            </NavLink>
          </div>
        </li>
        <li className='ms-1'>
          <div style={{ marginRight: "40px", }}>
             <NavLink
              to="/early"
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
             <button type="button" style={{backgroundColor:' #5E17EB', color:'white'}} className="btn">Request Early Access</button>
            </NavLink>
          </div>
        </li>
        </>
        :
        <></>
        }
        {
          nameProfile !== ''?
          <li className='ms-1'>
          <div style={{ marginRight: "40px", }}>
             <NavLink
              className={
                color
                  ? `navbar-bg ${menuOpen ? "open" : ""}`
                  : `link-color ${menuOpen ? "open" : ""}`
              }
              style={{ textDecoration: "none" }}
            >
<Dropdown>
      <Dropdown.Toggle style={{backgroundColor:' #5E17EB', color:'white'}}  id="dropdown-basic">
      <CgProfile className='me-3'/>{nameProfile}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item href="#/action-1">My Menu</Dropdown.Item> */}
        <Dropdown.Item onClick={(e)=>{logout(e)}} href="#/action-2">Log Out</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
            </NavLink>
          </div>
        </li>
        :<></>
        }
    
      </ul>
      <div
        className={`menu-toggle ${menuOpen ? "open" : ""}`}
        onClick={handleMenuToggle}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </nav>


  );
};

export default Navbar;
