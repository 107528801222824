import React,{useEffect, useState} from 'react'
import {BsGoogle} from 'react-icons/bs'
import { Modal, Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ThreeDots} from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios'
import Logo from '../../Images/navbar-logo.png'
import { useNavigate } from 'react-router-dom';

const CustomModal = ({ show, handleClose, title, bodyContent }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bodyContent}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Section1SignUp = () => {

  const BACKEND = process.env.REACT_APP_BACKEND

  const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [password, setPassword] = useState('');
    const [cpassword, setCPassword] = useState('');
    const[name,setName]=useState('')
    const[lastname,setLastName]=useState('')
    const[phone,setPhone]=useState('')
    const[utChoice,setUtChoice]=useState('user')
    const [isButtonDisabled,setIsButtonDisabled]=useState(true)
    const[loading, setLoading] = useState(false);
    const [showOTP,setShowOTP] = useState(false)
    const [otp,setOtp] = useState('')
    const [loading2,setLoading2]=useState('')
    const[showMainform,setShowmainform]=useState(true)



    const handleSubmit=(e)=>{
      e.preventDefault()
      console.log("handle submit")
      setLoading(true)

      if(password === '' || name === '' || username === '' || phone ===''){
        toast.error("Fill all the Fields");
        setLoading(false);
        return; 
      }

      if(password !== cpassword){
        toast.error("Recheck your password");
        setLoading(false);
        return; 
      }

      const nameRegex = /[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/;
      if (nameRegex.test(name)) {
        toast.error("Name cannot contain numbers or symbols");
        setLoading(false);
        return;
      }
      if (nameRegex.test(lastname)) {
        toast.error("Name cannot contain numbers or symbols");
        setLoading(false);
        return;
      }
    
    
      if (password.length < 8) {
        console.log("pass")
        toast.error("Password must be at least 8 characters long");
        setLoading(false);
        return;
      }
      const passRegex = /\s{2,}/;
      if (passRegex.test(password)) {
        toast.error("Invalid Password Format, 2 consecutive white spaces");
        setLoading(false);
        return;
      }

      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      if (!emailRegex.test(username)) {
        toast.error("Invalid Email Format");
        setLoading(false);
        return;
      }

       const phoneRegex = /^(?:\d{10})$/;
if(phone !== ''){
  if (!phoneRegex.test(phone)) {
    toast.error("Invalid Phone Number Format");
    setLoading(false);
    return;
  }
}

      axios.post(`${BACKEND}signup`,{name,lastname,phone,username,password,utChoice},{
        headers: {
          'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
      }).then(res => {if(res.data=='ok'){console.log(res.data);setLoading(false);toast.success("Verification Code sent, check email");setShowOTP(true);setShowmainform(false)}
      else{console.log(res.data.err);setLoading(false);toast.error(res.data.err)}})
      .catch(err => {console.log(err,"l");setLoading(false);toast.error("Some Error Occured")})
    }

   
    const handleOtp=(e)=>{
      e.preventDefault()
      console.log("handle submit")
      setLoading(true)


      axios.post(`${BACKEND}signup/otp`,{otp,username},{
        headers: {
          'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
      }).then(res => {if(res.data=='ok'){console.log(res.data);setLoading(false);
        // toast.success("Sign Up Successful")  ;
      setModalTitle('Sign Up Successful');
      setModalBodyContent('You have successfully signed up!');
      setShowModal(true);
      // setTimeout(() => { navigate('/signin') }, 2000)
    }
      else if(res.data.error == 'error'){console.log(res.data.msg);setOtp('');setLoading(false);toast.error(res.data.msg);setShowOTP(false);setShowmainform(true)}
      else{console.log(res.data.err);setLoading(false);toast.error(res.data.err);setOtp('');setShowmainform(true);setShowOTP(false)}}).catch(err => {console.log(err,"l");setLoading(false);toast.error("Some Error Occured");setOtp('');setShowmainform(true);setShowOTP(false)})
    }

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBodyContent, setModalBodyContent] = useState('');

    const handleModalClose = () => {
      setShowModal(false);
      setTimeout(() => { navigate('/signin') }, 2000)
    }


  return (
    <div style={{ backgroundColor: '#E3E2F8', overflow:'hidden' }}>
    <div  style={{paddingTop:'100px', paddingBottom:'150px', height:'100%'}}>
    <div className='d-flex justify-content-center col-12'  >
    <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent"}}
            className='text-center '
          />
    </div>

    {
      showMainform ?
      <div className='d-flex justify-content-center col-12 mb-5 pb-5'  >
      <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1 " style={{width:'50%',minWidth:'270px',backgroundColor:'white',  border:'1px solid lightgrey'}}>
      <div className="row">
        <h3 className='text-center'>Sign Up</h3>
        <div className='form-group d-flex justify-content-center mt-4'>
         {loading ? (
          // <div>hello</div>
            <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
           
          ) : (
            ''
          )}
          </div>
        
  
  
        {/* <div className="col-sm-12">
          <label htmlFor="email" className="mb-1">
            Google Login / Sign up
          </label>
          <div className="input-group mb-3">
           <button className='btn btn-dark col-12 '
          //   onClick={(e)=>{handleGoogle(e)}} 
            >Google <BsGoogle style={{color:'white'}} className='ms-2'/></button>
          </div>
        </div> */}
  
  
  <div className="col-lg-6 col-12">
          <label htmlFor="text" className="mb-1">
            First Name<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="nameFreelancer"
              required
              aria-label="Name"
              value={name.replace(/[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/g, '')}
              onChange={(e)=>setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <label htmlFor="text" className="mb-1">
            Last Name<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name"
              id="nameFreelancer"
              required
              aria-label="Name"
              value={lastname.replace(/[0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|~`]/g, '')}
              onChange={(e)=>setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <label htmlFor="email" className="mb-1">
            Email<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              id="emailFreelancer"
              required
              aria-label="email"
              value={username}
              onChange={(e)=>setUsername(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <label htmlFor="text" className="mb-1">
            Phone Number<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              id="phoneFreelancer"
              required
              aria-label="number"
              value={phone.replace(/[^0-9+]/g, '')}
              onChange={(e)=>setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className=" col-lg-6 col-12">
          <label htmlFor="password" className="mb-1">
            Password<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              id="passwordFreelancer"
              required
              aria-label="Password"
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className=" col-lg-6 col-12">
          <label htmlFor="password" className="mb-1">
            Confirm Password<span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Confirm Password"
              id="passwordFreelancer"
              required
              aria-label="Password"
              value={cpassword}
              onChange={(e)=>setCPassword(e.target.value)}
            />
          </div>
        </div>
  
  <div className='d-flex '>
  <div className="form-check ps-0">
    <input className="form-check-input " style={{marginLeft:'0px'}} type="radio" name="flexRadioDefault" id="flexRadioDefault1" value='user' onClick={(e)=>{setUtChoice(e.target.value)}} checked/>
    <label className="form-check-label  px-1 ms-3" for="flexRadioDefault1">
      User
    </label>
  </div>
  <div className="form-check mx-3 ps-0">
    <input className="form-check-input  ms-2" type="radio" name="flexRadioDefault" value='trader' onClick={(e)=>{setUtChoice(e.target.value)}} id="flexRadioDefault2" />
    <label className="form-check-label px-1 ms-4" for="flexRadioDefault2">
      Trader
    </label>
  </div>
  </div>
  
  
  <div className="col-sm-12">
          <div className="input-group mt-2">
          <Link to='/signin' style={{ textDecoration:'none'}}><Nav.Link href="#link" className='btn link' style={{ color:'#5E17EB' }}>Already have an Account? Log In</Nav.Link></Link>        
          </div>
        </div>
  
        {/* <div class="col-12 pb-2">
      <input type="checkbox" className="form-check-input me-3" value="Admin" onChange={(e)=>{if(e.target.checked === true){setUserType(e.target.value)}else{setUserType('None')}}} id="exampleCheck1"/>
      <label className="form-check-label" for="exampleCheck1">Admin</label>
    </div> */}
        <div className="col-12 py-2">
          <button
            type="submit"
          //   disabled={isButtonDisabled}
            className="btn  mt-3 d-block col-lg-6 col-12 mx-auto"
            style={{backgroundColor:'#5E17EB', color:'white'}}
             onClick={(e) => handleSubmit(e)}
          >
            Sign Up
          </button>
        </div>
      </div> 
    </form>
    </div>:<></>
    }
 
 
  { showOTP ?
  <div className='d-flex justify-content-center col-12 mb-5' style={{paddingTop:'60px',paddingBottom:'50px', }} >
  <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1" style={{width:'330px',minWidth:'270px',backgroundColor:'white',  border:'1px solid lightgrey'}}>
<div className="row">
  <div className="col-sm-12">
    <label htmlFor="email" className="mb-1">
     Check for Verification Code in your email
    </label>
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Verification Code"
        id="emailFreelancer"
        required
        aria-label="Verification Code"
        value={otp}
        onChange={(e)=>setOtp(e.target.value)}
      />
    </div>
  </div>
  <div className="col-12">
    <button
      type="submit"
      className="btn btn-primary mt-3 d-block w-100"
      style={{backgroundColor:'#5E17EB', color:'white'}}
       onClick={(e) => handleOtp(e)}
    >
      Sign Up
    </button>
  </div>
</div>
<div className='form-group d-flex justify-content-center mt-4'>
   {loading2 ? (
      <ThreeDots type="Oval" position="top-center" color="#fff" height={50} width={50} />
     
    ) : (
      ''
    )}
    </div>

</form>
  </div> : ''
  }

<CustomModal
        show={showModal}
        handleClose={handleModalClose}
        title={modalTitle}
        bodyContent={modalBodyContent}
      />

  </div>
  <ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
  </div>
  )
}

export default Section1SignUp