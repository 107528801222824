import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import { FaRegSquarePlus } from "react-icons/fa6";
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Accordion from 'react-bootstrap/Accordion';
import Cookies from 'js-cookie'
import Logo from '../../Images/navbar-logo.png'
import '../TraderDash/traderDash.css'
import { FaHistory } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoReorderThree } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { IoPeopleSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import { Modal } from 'react-bootstrap';
import { Dropdown } from 'react-bootstrap';

import { Link, NavLink, useLocation } from "react-router-dom";





const Section1TraderDash = () => {


  const BACKEND = process.env.REACT_APP_BACKEND

  const [radioValue, setRadioValue] = useState('Stock');
  const [utChoice, setUtChoice] = useState('Stock')

  const [ticker, setTicker] = useState('')
  const [direction, setDirection] = useState('')
  const [priceAtIndication, setPriceAtIndication] = useState('')
  const [sellAt, setSellAt] = useState('')
  const [closeAt, setCloseAt] = useState('')
  const [strikePrice, setStrikePrice] = useState('')
  const [strikeDate, setStrikeDate] = useState('')

  const [historyStock, setHistoryStock] = useState([])
  const [historyOption, setHistoryOption] = useState([])
  const [sublist, setSublist] = useState([])

  const [showModalP, setShowModalP] = useState(false);
  const [showModalL, setShowModalL] = useState(false);



  const [sideNavChoose, setSideNavChoose] = useState('Trade History')

  const handleSideNav = (e) => {
    // e.preventDefault()
    console.log(e)
    setSideNavChoose(e)
  }


  useEffect(() => {
    axios.get(`${BACKEND}traderDash/tradehistory`, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => {
        if (res.data.message == 'ok') { setHistoryOption(res.data.optionsData); setHistoryStock(res.data.stocksData); console.log(res.data.optionsData, " ", res.data.stocksData) }
        else if (res.data.error) { console.log(res.data.error); toast.error(res.data.error) }
        else if (res.data.ND) { console.log(res.data.ND) }
        else { console.log(res.data) }
      })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })

  }, [])

  useEffect(() => {
    axios.get(`${BACKEND}traderDash/subscribers`, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => {
        if (res.data.message == 'ok') { console.log(res.data.subscribers); setSublist(res.data.subscribers) }
        else if (res.data.error) { console.log(res.data.error); toast.error(res.data.error) }
        else { console.log(res.data) }
      })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })

  }, [])

  const radios = [
    { name: 'Stock', value: 'Stock' },
    { name: 'Options', value: 'Options' },
  ];

  const handleForm = () => {
    console.log(radioValue)
    setUtChoice(radioValue)
  }

  const handleStockSub = (e) => {
    e.preventDefault()

    const tickerRegex = /^[A-Za-z0-9]{4}$/;
    if (!tickerRegex.test(ticker)) {
      toast.error('Ticker must be a 4-character code');
      return;
    }
    if (ticker === '' || direction === '' || priceAtIndication === '' || sellAt === '' || closeAt === '') {
      toast.error('All Fields are Mandatory');
      return;
    }
    if (Number(priceAtIndication) === 0) {
      toast.error("Price can't be Zero");
      return;
    }
    if (priceAtIndication === sellAt || priceAtIndication === closeAt || closeAt === sellAt) {
      toast.error("Values can't be same");
      return;
    }
    console.log(priceAtIndication, sellAt, closeAt)


    axios.post(`${BACKEND}traderDash/newstock`, { ticker, direction, priceAtIndication, sellAt, closeAt }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => {
        if (res.data.message == 'ok') {
          toast.success('Stock Created Successfully');
          setTimeout(() => {
            window.location.href = window.location.href + '#sh';
            window.location.reload(true);
          },
            2000)
        } else if (res.data.error) { toast.error(res.data.error); console.log(res.data.error) }
      })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }

  const handleOptionSub = (e) => {
    e.preventDefault()

    const tickerRegex = /^[A-Za-z0-9]{4}$/;
    if (!tickerRegex.test(ticker)) {
      toast.error('Ticker must be a 4-character code');
      return;
    }


    const strikeRegex = /^\d*\.?\d*$/;
    if (!strikeRegex.test(strikePrice)) {
      toast.error('Strike Price can only be Numbers');
      return;
    }

    if (ticker === '' || direction === '' || priceAtIndication === '' || sellAt === '' || closeAt === '' || strikePrice === '' || strikeDate === '') {
      toast.error('All Fields are Mandatory');
      return;
    }
    if (Number(priceAtIndication) === 0) {
      toast.error("Price can't be Zero");
      return;
    }
    if (priceAtIndication === sellAt || priceAtIndication === closeAt || closeAt === sellAt) {
      toast.error("Values can't be same");
      return;
    }
    console.log(priceAtIndication, sellAt, closeAt)


    axios.post(`${BACKEND}traderDash/newoption`, { ticker, direction, priceAtIndication, sellAt, closeAt, strikePrice, strikeDate }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => { if (res.data.message == 'ok') { toast.success('Option Created Successfully'); 
      setTimeout(() => {
        window.location.href = window.location.href + '#oh';
        window.location.reload(true);
      },
        2000)
    }
       else if (res.data.error) { toast.error(res.data.error); console.log(res.data.error) } })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })

  }


  const calculatePercentage = (close) => {
    if (parseFloat(priceAtIndication) !== 0) {
      const percentage = ((parseFloat(close) - parseFloat(priceAtIndication)) / parseFloat(priceAtIndication)) * 100;
      setSellAt(percentage.toFixed(2));
    }
  };

  // Function to calculate Close At based on Price At Indication and Percentage
  const calculateCloseAt = (sell) => {
    if (parseFloat(priceAtIndication) !== 0) {
      console.log(parseFloat(priceAtIndication), "===", parseFloat(sell), "---", sell)
      const closeAtValue = parseFloat(priceAtIndication) * (1 + parseFloat(sell) / 100);
      setCloseAt(closeAtValue.toFixed(2));
    }
  };

  // const handleSellAtSelect=()=>{
  //   console.log('yes')
  // }

  // Handle changes in Sell At Percentage
  const handleSellAtChange = async (e) => {
    const input = await e.target.value;
    console.log(input)


    const regex = /^(\d+(\.\d{0,2})?|100)$/;
    if (regex.test(input) || input === '') {
      console.log("ii", input)

      setSellAt(input);
      if (input === NaN) {
        setSellAt('')
      }
      console.log(sellAt)
      // calculateCloseAt(input); // Recalculate Close At when Sell At Percentage changes
    }
  };

  const handlePriceChange = (e) => {
    const input = e.target.value;

    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(input) || input === '') {
      setPriceAtIndication(input);
      // calculatePercentage(); // Recalculate percentage when Price At Indication changes
    }
  };

  const handleStrikePriceChange = (e) => {
    const input = e.target.value;
    console.log(input)

    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(input) || input === '') {
      setStrikePrice(input);
    }
  };


  // Handle changes in Sell At Close
  const handleCloseAtChange = async (e) => {
    const input = e.target.value;

    const regex = /^\d+(\.\d{0,2})?$/;
    if (regex.test(input) || input === '') {

      setCloseAt(input);

      // calculatePercentage(input); // Recalculate percentage when Close At changes
    }
  };

  useEffect(() => {
    if (closeAt === 'NaN') {
      console.log(closeAt, "weeee")
      setCloseAt('')
    }
  }, [closeAt])

  useEffect(() => {
    if (sellAt === 'NaN') {
      setSellAt('')
    }
  }, [sellAt])

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [cross, setCross] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 768);
      setCross(screenWidth < 768)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [searchTermStock, setSearchTermStock] = useState('');
  const [filteredDataStock, setFilteredDataStock] = useState(historyStock);

  const [searchTermSub, setSearchTermSub] = useState('');
  const [filteredDataSub, setFilteredDataSub] = useState(sublist);

  const [searchTermOption, setSearchTermOption] = useState('');
  const [filteredDataOption, setFilteredDataOption] = useState(historyOption);

  const handleSearchSub = (e) => {
    const input = e.target.value;
    setSearchTermSub(input);

    const filtered = sublist.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });
    console.log(filtered)
    setFilteredDataSub(filtered);
  };

  const handleSearchStock = (e) => {
    const input = e.target.value;
    setSearchTermStock(input);

    const filtered = historyStock.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });

    setFilteredDataStock(filtered);
  };

  const handleSearchOption = (e) => {
    const input = e.target.value;
    setSearchTermOption(input);
    console.log(input)

    console.log('P', historyOption)

    const filtered = historyOption.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });
    console.log(filtered)
    setFilteredDataOption(filtered);
  };

  const [histchoice, setHistchoice] = useState('S')

  const [selectedRadio, setSelectedRadio] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedId, setSelectedId] = useState('')

  const handleRadioChange = (radioValue, type) => {
    setSelectedRadio(radioValue);
    setSelectedType(type);
  };

  const handleLossSwitch = (id, typeP, typeT, pai, sellAt, closeAt, direction) => {
    setSelectedType(typeP)
    setSelectedId(id)
    axios.post(`${BACKEND}traderDash/declareloss`, { id, typeP, typeT, pai, sellAt, closeAt, direction }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => {
        if (res.data.message == 'ok') { toast.success('Declared a Loss'); setTimeout(() => { window.location.reload(true) }, 1000) }
        else if (res.data.error) { toast.error('Some Error Occured'); console.log(res.data.error) }
      })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })



  }
  const handleProfitSwitch = (id, typeP, typeT, pai, sellAt, closeAt, direction) => {
    setSelectedType(typeP)
    setSelectedId(id)
    axios.post(`${BACKEND}traderDash/declareprofit`, { id, typeP, typeT, pai, sellAt, closeAt, direction }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => {
        if (res.data.message == 'ok') { toast.success('Declared a Profit'); setTimeout(() => { window.location.reload(true) }, 1000) }
        else if (res.data.error) { toast.error('Some Error Occured'); console.log(res.data.error) }
      })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }


  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log('path', location)

 

  useEffect(()=>{
if(hash === '#st'){
  setUtChoice('Stock'); setSideNavChoose('Add Trade')
}else if(hash === '#ot'){
  setUtChoice('Options'); setSideNavChoose('Add Trade')
}else if(hash === '#sh'){
  setHistchoice('S'); setSideNavChoose('Trade History')
}else if(hash === '#oh'){
  setHistchoice('O'); setSideNavChoose('Trade History')
}else if(hash === '#ss'){
setSideNavChoose('Subscribers')
}
if (window.history) {
  // Get the current URL
  let currentUrl = window.location.href;

  // Remove the fragment identifier
  let newUrl = currentUrl.replace(/#.*$/, '');

  // Replace the current state in the history without triggering a page reload
  window.history.replaceState(null, '', newUrl);
}
  },[hash])

  return (
    <div style={{ backgroundColor: '#E3E2F8', overflow: 'hidden' }}>
      <ToastContainer position="top-center" />


      <IoReorderThree size={42} style={{ top: '50px', position: 'fixed', zIndex: '2' }} onClick={() => setIsSmallScreen(false)} />


      {/* SIDE NAVBAR */}

      {/* <aside style={{position: 'fixed', overflowY: 'auto', minHeight:'100vh' }} className=" main-sidebar  sidebar-dark-primary elevation-4"> */}
      <aside style={{ position: 'fixed', top: '0px', overflowY: 'auto', minHeight: '100vh', zIndex: '2', maxWidth: '268px', opacity: 1, color: '#343A40' }} className={`
     sidebar-dark-primary
       elevation-4 ${!isSmallScreen ? '' : 'd-none'}`}>

        <a className="brand-link" style={{ height: '55px' }}>
        </a>
        {/* <div className="logo" style={{ height:'55px'}}>
        <Link to="/">
          <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent"}}
            className='my-2'
          />
        </Link>
      </div> */}
        <div className={`d-flex m-2 ${isSmallScreen == false ? '' : 'd-none'}`} style={{ color: 'white' }} onClick={() => setIsSmallScreen(true)}>
          <RxCrossCircled className={`ms-auto ${cross ? '' : 'd-none'}`} />
        </div>


        <div className="sidebar">
          <div className="form-inline">
            <div className="input-group my-4" data-widget="sidebar-search">
              <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append ">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw" />
                </button>
              </div>
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar col-12 flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-header col-12">AITS Trader Dashboard</li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Trade History' style={{ color: 'white' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => handleSideNav(e.target.value)} >
                  <FaHistory style={{ color: 'white' }} className='me-2' />Trade History
                </button>
                <ul className="  fs-6 ms-2" data-accordion="false">
                  <li className="nav-item col-12">
                    <button value='Trade History' style={{ color: 'white', backgroundColor: sideNavChoose === 'Trade History' && histchoice === 'S' ? '#494E53' : '' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => { handleSideNav(e.target.value); setHistchoice('S') }} >
                      Stock
                    </button>
                  </li>
                  <li className="nav-item col-12 d-flex flex-wrap">
                    <button value='Trade History' style={{ color: 'white', backgroundColor: sideNavChoose === 'Trade History' && histchoice === 'O' ? '#494E53' : '' }} onClick={(e) => { handleSideNav(e.target.value); setHistchoice('O') }} className="nav-link fs-6 text-start col-12 btn btn-link">
                      Option
                    </button>
                  </li>
                </ul>
              </li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Add Trade' style={{ color: 'white' }} onClick={(e) => handleSideNav(e.target.value)} className="nav-link text-start col-12 btn btn-link">
                  <IoMdAddCircleOutline style={{ color: 'white' }} className='me-2' /> Add Trade
                </button>
                <ul className="  fs-6 ms-2" data-accordion="false">
                  <li className="nav-item col-12">
                    <button value='Add Trade' style={{ color: 'white', backgroundColor: utChoice === 'Stock' && sideNavChoose === 'Add Trade' ? '#494E53' : '' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => { handleSideNav(e.target.value); setUtChoice('Stock') }} >
                      Stock
                    </button>
                  </li>
                  <li className="nav-item col-12 d-flex flex-wrap">
                    <button value='Add Trade' style={{ color: 'white', backgroundColor: utChoice === 'Options' && sideNavChoose === 'Add Trade' ? '#494E53' : '' }} onClick={(e) => { handleSideNav(e.target.value); setUtChoice('Options') }} className="nav-link fs-6 text-start col-12 btn btn-link">
                      Option
                    </button>
                  </li>


                </ul>
              </li>

              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Subscribers' style={{ color: 'white', backgroundColor: sideNavChoose === 'Subscribers' ? '#494E53' : '' }} onClick={(e) => handleSideNav(e.target.value)} className="nav-link text-start col-12 btn btn-link">
                  <IoPeopleSharp style={{ color: 'white' }} className='me-2' /> Subscribers
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </aside>

      <div className='responsive-side-nav-other d-block' style={{ paddingTop: '100px', paddingBottom: '150px', height: '100%', minHeight: '80vh' }}>
        <h1 className='d-flex justify-content-center'>Trader Dashboard</h1>


        {
          utChoice === 'Stock' && sideNavChoose === 'Add Trade' ?
            <div className='row col-12 col-sm-9 mx-auto mt-4 d-flex '>
              <Card style={{ backgroundColor: 'white' }} className='  d-flex rounded-4'>
                <Card.Body className="d-flex flex-column" style={{}}>
                  <h3 className=' mt-2 d-flex justify-content-between text-center mx-auto pb-2' style={{ borderBottom: '1px solid black', display: 'flex', flexWrap: 'wrap' }}>
                    Stock
                  </h3>


                  <div className='row'>
                    <div className="col-lg-6 col-12">
                      <label htmlFor="ticker" className="mb-1">
                        Ticker<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <select
                          className="form-control"
                          id="direction"
                          value={ticker}
                          placeholder=''
                          onChange={(e) => setTicker(e.target.value)}
                        >
                          <option value="">4 Character Stock Code</option>
                          <option value="CHAD">CHAD</option>
                          <option value="OTIS">OTIS</option>
                          <option value="MXIN">MXIN</option>
                          <option value="TZWA">TZWA</option>
                        </select>
                        {/* <div className="input-group-append">
    <span className="input-group-text">&#x25BC;</span>
  </div> */}
                      </div>
                    </div>



                    <div className="col-lg-6 col-12">
                      <label htmlFor="direction" className="mb-1">
                        Direction<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <select
                          className="form-control"
                          id="direction"
                          value={direction}
                          onChange={(e) => setDirection(e.target.value)}
                        >
                          <option value="">Select Direction</option>
                          <option value="Long">Long</option>
                          <option value="Short">Short</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <label htmlFor="priceAtIndication" className="mb-1">
                        Price At Indication (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Price At Indication"
                          id="priceAtIndication"
                          value={priceAtIndication}
                          onChange={handlePriceChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="sellAtPercentage" className="mb-1">
                        Sell At (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Percentage"
                          id="sellAtPercentage"
                          value={sellAt}

                          onChange={handleSellAtChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="sellAtClose" className="mb-1">
                        Stop Loss (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Stop Loss"
                          id="sellAtClose"
                          value={closeAt}
                          onChange={handleCloseAtChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <Button variant="warning" onClick={(e) => handleStockSub(e)} style={{ marginTop: '28px' }}>Submit</Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> : <></>
        }
        {
          utChoice == 'Options' && sideNavChoose === 'Add Trade' ?
            <div className='row col-12 col-sm-9 mx-auto mt-4 d-flex '>
              <Card style={{ backgroundColor: 'white' }} className='  d-flex rounded-4'>
                <Card.Body className="d-flex flex-column" style={{}}>
                  <h3 className=' mt-2 d-flex justify-content-between text-center mx-auto pb-2' style={{ borderBottom: '1px solid black', display: 'flex', flexWrap: 'wrap' }}>
                    Option
                  </h3>
                  <div className='row'>
                    <div className="col-lg-6 col-12">
                      <label htmlFor="ticker" className="mb-1">
                        Ticker<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <select
                          className="form-control "
                          id="direction"
                          value={ticker}
                          onChange={(e) => setTicker(e.target.value)}
                        >
                          <option value=""  >4 Character Option Code </option>
                          <option value="CHAD">CHAD</option>
                          <option value="OTIS">OTIS</option>
                          <option value="MXIN">MXIN</option>
                          <option value="TZWA">TZWA</option>
                        </select>
                      </div>
                    </div>



                    <div className="col-lg-6 col-12">
                      <label htmlFor="direction" className="mb-1">
                        Direction<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <select
                          className="form-control"
                          id="direction"
                          value={direction}
                          onChange={(e) => setDirection(e.target.value)}
                        >
                          <option value="">Select Direction</option>
                          <option value="Long">Long</option>
                          <option value="Short">Short</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <label htmlFor="text" className="mb-1">
                        Strike Price (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Strike Price"
                          id="nameFreelancer"
                          required
                          aria-label="Name"
                          value={strikePrice}
                          onChange={handleStrikePriceChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="strikeDate" className="mb-1">
                        Strike Date<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="date"
                          className="form-control"
                          id="strikeDate"
                          required
                          aria-label="Strike Date"
                          value={strikeDate}
                          onChange={(e) => setStrikeDate(e.target.value)}
                        />
                      </div>

                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="priceAtIndication" className="mb-1">
                        Price At Indication (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Price At"
                          id="priceAtIndication"
                          value={priceAtIndication}
                          onChange={handlePriceChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="sellAtPercentage" className="mb-1">
                        Sell At (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Percentage"
                          id="sellAtPercentage"
                          value={sellAt}
                          onChange={handleSellAtChange}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-12">
                      <label htmlFor="sellAtClose" className="mb-1">
                        Stop Loss (in USD)<span className="text-danger">*</span>
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text" // Use type text to allow regex validation
                          className="form-control"
                          placeholder="Stop Loss"
                          id="sellAtClose"
                          value={closeAt}
                          onChange={handleCloseAtChange}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <Button variant="warning" onClick={(e) => handleOptionSub(e)} style={{ marginTop: '28px' }}>Submit</Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div> : <></>
        }
        {
          sideNavChoose === 'Trade History' && histchoice === 'S' ?

            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>

              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Stock History</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermStock} onChange={handleSearchStock} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                        <i className="fas fa-search fa-fw" style={{ color: '#343A40' }} />

                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Ticker</th>
                        <th>Direction</th>
                        <th>Price at Indication</th>
                        <th>Sell At</th>
                        <th>Stop Loss</th>
                        <th>Date</th>
                        <th>Performance</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataStock.length === 0 ? historyStock.length === 0 ? <h5 className='my-2'>No Records</h5> :
                        historyStock.map((stock, stockIndex) => ({ index: stockIndex, date: new Date(stock.time), ...stock }))
                          .sort((a, b) => b.date - a.date)
                          .map(({ stockIndex, date, ...stock }) => (
                            <tr key={stockIndex}>
                              <td>
                                {stock.ticker}
                              </td>
                              <td>
                                {stock.direction}
                              </td>
                              <td>{stock.priceAtIndication} $</td>
                              <td>{stock.sellAt} $</td>
                              <td>{stock.closeAt} $</td>
                              <td>
                                {stock.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {stock.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                              </td>

                              {stock.performance === 'TBD' ?
                                <td>
                                  <td>
                                    {
                                      selectedId !== stock._id ?
                                        <button value={stock._id} onClick={() => { setSelectedId(stock._id) }} type="button" class="btn btn-secondary btn-sm">TBD</button> : ''
                                    }
                                    {
                                      selectedId === stock._id ?
                                        <Form>
                                          <div key={`profit-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`profit-checkbox` + stock._id}
                                              label={`Profit`}
                                              name="profitLossRadio"
                                              value={stock._id}
                                              checked={selectedId === stock._id && selectedType === 'Profit'}
                                              onChange={() => { setShowModalP(true); console.log(showModalP) }}
                                            //  onChange={() => handleProfitSwitch(stock._id, 'Profit','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalP} onHide={() => setShowModalP(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Profit?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalP(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleProfitSwitch(stock._id, 'Profit', 'stocks', stock.priceAtIndication, stock.sellAt, stock.closeAt, stock.direction); setShowModalP(false)}}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>

                                          <div key={`loss-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`loss-checkbox` + stock._id}
                                              label={`Loss`}
                                              name="profitLossRadio"
                                              value={stock._id}
                                              checked={selectedId === stock._id && selectedType === 'Loss'}
                                              onChange={() => { setShowModalL(true); console.log(showModalL) }}

                                            //  onChange={() => handleLossSwitch(stock._id, 'Loss','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalL} onHide={() => setShowModalL(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Loss?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalL(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => { handleLossSwitch(stock._id, 'Loss', 'stocks', stock.priceAtIndication, stock.sellAt, stock.closeAt, stock.direction); console.log("loss");setShowModalL(false) }}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`close-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`close-checkbox` + stock._id}
                                              label={`Close`}
                                              name="profitLossRadio"
                                              value='Close'
                                              //  checked={selectedId === option._id && selectedType === ''}
                                              onChange={() => setSelectedId('')}
                                            />
                                          </div>
                                        </Form> : ''}
                                  </td>

                                </td> : <td>{stock.performance}</td>
                              }

                            </tr>
                          )) :
                        filteredDataStock.map((stock, stockIndex) => ({ index: stockIndex, date: new Date(stock.time), ...stock }))
                          .sort((a, b) => b.date - a.date)
                          .map(({ stockIndex, date, ...stock }) => (
                            <tr key={stockIndex}>
                              <td>
                                {/* <img src="dist/img/default-150x150.png" alt="Product 1" className="img-circle img-size-32 mr-2" /> */}
                                {stock.ticker}
                              </td>
                              <td>
                                {stock.direction}
                              </td>
                              <td>{stock.priceAtIndication} $</td>
                              <td>{stock.sellAt} $</td>
                              <td>{stock.closeAt} $</td>
                              <td>
                                {stock.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {stock.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                              </td>
                              {stock.performance === 'TBD' ?
                                <td>
                                  <td>
                                    {
                                      selectedId !== stock._id ?
                                        <button value={stock._id} onClick={() => { setSelectedId(stock._id) }} type="button" class="btn btn-secondary btn-sm">TBD</button> : ''
                                    }
                                    {
                                      selectedId === stock._id ?
                                        <Form>
                                          <div key={`profit-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`profit-checkbox` + stock._id}
                                              label={`Profit`}
                                              name="profitLossRadio"
                                              value={stock._id}
                                              checked={selectedId === stock._id && selectedType === 'Profit'}
                                              onChange={() => { setShowModalP(true); console.log(showModalP) }}

                                            //  onChange={() => handleProfitSwitch(stock._id, 'Profit','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalP} onHide={() => setShowModalP(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Profit?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalP(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleProfitSwitch(stock._id, 'Profit', 'stocks', stock.priceAtIndication, stock.sellAt, stock.closeAt, stock.direction);setShowModalP(false)}}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`loss-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`loss-checkbox` + stock._id}
                                              label={`Loss`}
                                              name="profitLossRadio"
                                              value={stock._id}
                                              checked={selectedId === stock._id && selectedType === 'Loss'}
                                              onChange={() => { setShowModalL(true); console.log(showModalL) }}

                                            //  onChange={() => handleLossSwitch(stock._id, 'Loss','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalL} onHide={() => setShowModalL(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Loss?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalL(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleLossSwitch(stock._id, 'Loss', 'stocks', stock.priceAtIndication, stock.sellAt, stock.closeAt, stock.direction);setShowModalL(false)}}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`close-checkbox` + stock._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`close-checkbox` + stock._id}
                                              label={`Close`}
                                              name="profitLossRadio"
                                              value='Close'
                                              //  checked={selectedId === option._id && selectedType === ''}
                                              onChange={() => setSelectedId('')}
                                            />
                                          </div>
                                        </Form> : ''}
                                  </td>

                                </td> : <td>{stock.performance}</td>
                              }
                            </tr>
                          ))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div> : <></>
        }
        {
          sideNavChoose === 'Trade History' && histchoice === 'O' ?
            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>
              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Option History</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermOption} onChange={handleSearchOption} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                        <i className="fas fa-search fa-fw" style={{ color: '#343A40' }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Ticker</th>
                        <th>Strike Price</th>
                        <th>Strike Date</th>
                        <th>Direction</th>
                        <th>Price at Indication</th>
                        <th>Sell At</th>
                        <th>Stop Loss</th>
                        <th>Date</th>
                        <th>Performance</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataOption.length === 0 ? historyOption.length === 0 ? <h5 className='my-2'>No Records</h5> :
                        historyOption.map((option, optionIndex) => ({ index: optionIndex, date: new Date(option.time), ...option }))
                          .sort((a, b) => b.date - a.date)
                          .map(({ optionIndex, date, ...option }) => (
                            <tr key={optionIndex}>
                              <td>
                                {option.ticker}
                              </td>
                              <td>{option.strikePrice} $</td>
                              <td>{option.strikeDate.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} </td>
                              <td>
                                {option.direction}
                              </td>
                              <td>{option.priceAtIndication} $</td>
                              <td>{option.sellAt} $</td>
                              <td>{option.closeAt} $</td>
                              <td>
                                {option.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {option.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                              </td>
                              {option.performance === 'TBD' ?
                                <td>
                                  <td>
                                    {
                                      selectedId !== option._id ?
                                        <button value={option._id} onClick={() => { setSelectedId(option._id) }} type="button" class="btn btn-secondary btn-sm">TBD</button> : ''
                                    }
                                    {
                                      selectedId === option._id ?
                                        <Form>
                                          <div key={`profit-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`profit-checkbox` + option._id}
                                              label={`Profit`}
                                              name="profitLossRadio"
                                              value={option._id}
                                              checked={selectedId === option._id && selectedType === 'Profit'}
                                              onChange={() => { setShowModalP(true); console.log(showModalP) }}

                                            //  onChange={() => handleProfitSwitch(option._id, 'Profit','options',option.priceAtIndication,option.sellAt,option.closeAt,option.direction)}
                                            />
                                            <Modal show={showModalP} onHide={() => setShowModalP(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Profit?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalP(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleProfitSwitch(option._id, 'Profit', 'options', option.priceAtIndication, option.sellAt, option.closeAt, option.direction);setShowModalP(false)}}

                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`loss-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`loss-checkbox` + option._id}
                                              label={`Loss`}
                                              name="profitLossRadio"
                                              value={option._id}
                                              checked={selectedId === option._id && selectedType === 'Loss'}
                                              onChange={() => { setShowModalL(true); console.log(showModalL) }}

                                            //  onChange={() => handleLossSwitch(stock._id, 'Loss','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalL} onHide={() => setShowModalL(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Loss?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalL(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleLossSwitch(option._id, 'Loss', 'options', option.priceAtIndication, option.sellAt, option.closeAt, option.direction);setShowModalL(false)}}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`close-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`close-checkbox` + option._id}
                                              label={`Close`}
                                              name="profitLossRadio"
                                              value='Close'
                                              //  checked={selectedId === option._id && selectedType === ''}
                                              onChange={() => setSelectedId('')}
                                            />
                                          </div>
                                        </Form> : ''}
                                  </td>

                                </td> : <td>{option.performance}</td>
                              }
                            </tr>
                          )) :
                        filteredDataOption.map((option, optionIndex) => ({ index: optionIndex, date: new Date(option.time), ...option }))
                          .sort((a, b) => b.date - a.date)
                          .map(({ optionIndex, date, ...option }) => (
                            <tr key={optionIndex}>
                              <td>
                                {option.ticker}
                              </td>
                              <td>{option.strikePrice} $</td>
                              <td>{option.strikeDate.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} </td>
                              <td>
                                {option.direction}
                              </td>
                              <td>{option.priceAtIndication} $</td>
                              <td>{option.sellAt} $</td>
                              <td>{option.closeAt} $</td>
                              <td>
                                {option.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {option.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                              </td>
                              {option.performance === 'TBD' ?
                                <td>
                                  <td>
                                    {
                                      selectedId !== option._id ?
                                        <button value={option._id} onClick={() => { setSelectedId(option._id) }} type="button" class="btn btn-secondary btn-sm">TBD</button> : ''
                                    }
                                    {
                                      selectedId === option._id ?
                                        <Form>
                                          <div key={`profit-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`profit-checkbox` + option._id}
                                              label={`Profit`}
                                              name="profitLossRadio"
                                              value={option._id}
                                              checked={selectedId === option._id && selectedType === 'Profit'}
                                              onChange={() => { setShowModalP(true); console.log(showModalP) }}

                                            //  onChange={() => handleProfitSwitch(option._id, 'Profit','options',option.priceAtIndication,option.sellAt,option.closeAt,option.direction)}
                                            />
                                            <Modal show={showModalP} onHide={() => setShowModalP(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Profit?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalP(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleProfitSwitch(option._id, 'Profit', 'options', option.priceAtIndication, option.sellAt, option.closeAt, option.direction);setShowModalP(false)}}

                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`loss-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`loss-checkbox` + option._id}
                                              label={`Loss`}
                                              name="profitLossRadio"
                                              value={option._id}
                                              checked={selectedId === option._id && selectedType === 'Loss'}
                                              onChange={() => { setShowModalL(true); console.log(showModalL) }}

                                            //  onChange={() => handleLossSwitch(stock._id, 'Loss','stocks',stock.priceAtIndication,stock.sellAt,stock.closeAt,stock.direction)}
                                            />
                                            <Modal show={showModalL} onHide={() => setShowModalL(false)}>
                                              <Modal.Header closeButton>
                                                <Modal.Title>Confirmation</Modal.Title>
                                              </Modal.Header>
                                              <Modal.Body>
                                                Are you sure you want to declare this Trade a Loss?
                                              </Modal.Body>
                                              <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowModalL(false)}>
                                                  No
                                                </Button>
                                                <Button variant="secondary"
                                                  onClick={() => {handleLossSwitch(option._id, 'Loss', 'options', option.priceAtIndication, option.sellAt, option.closeAt, option.direction);setShowModalL(false)}}
                                                >
                                                  Yes
                                                </Button>
                                              </Modal.Footer>
                                            </Modal>
                                          </div>
                                          <div key={`close-checkbox` + option._id} className="mb-3">
                                            <Form.Check
                                              type='radio'
                                              id={`close-checkbox` + option._id}
                                              label={`Close`}
                                              name="profitLossRadio"
                                              value='Close'
                                              //  checked={selectedId === option._id && selectedType === ''}
                                              onChange={() => setSelectedId('')}
                                            />
                                          </div>

                                        </Form> : ''}
                                  </td>

                                </td> : <td>{option.performance}</td>
                              }
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            : <></>
        }
        {
          sideNavChoose === 'Subscribers' ?
            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>

              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Subscribers</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermSub} onChange={handleSearchSub} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                        <i className="fas fa-search fa-fw" style={{ color: '#343A40' }} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Subscribers</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataSub.length === 0 ? sublist.length === 0 ? <h5 className='my-2'>No Records</h5> : sublist.map((stock, stockIndex) => (

                        <tr key={stockIndex}>
                          <td>
                            {/* <img src="dist/img/default-150x150.png" alt="Product 1" className="img-circle img-size-32 mr-2" /> */}
                            {stockIndex + 1}
                          </td>
                          <td>{stock.subs}</td>

                        </tr>
                      )) :
                        filteredDataSub.map((stock, stockIndex) => (
                          <tr key={stockIndex}>
                            <td>
                              {/* <img src="dist/img/default-150x150.png" alt="Product 1" className="img-circle img-size-32 mr-2" /> */}
                              {stockIndex + 1}
                            </td>
                            <td>{stock.subs} </td>
                          </tr>
                        ))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div> : <></>
        }

      </div>
    </div>
  )
}

export default Section1TraderDash