import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card';
import { BsFillArrowLeftCircleFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import Cookies from 'js-cookie'
import axios from 'axios'
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import { FaHistory } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoReorderThree } from "react-icons/io5";
import { RxCrossCircled } from "react-icons/rx";
import { FaListUl } from "react-icons/fa6";
import { TiTick } from "react-icons/ti";
import { BsPeopleFill } from "react-icons/bs";
import { Modal, Button } from 'react-bootstrap';
import Logo from "../../Images/navbar-logo.png";
import { Link, NavLink } from "react-router-dom";


const CustomModal = ({ show, handleClose, title, bodyContent }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {bodyContent}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};



const Section1UserDash = () => {

  const BACKEND = process.env.REACT_APP_BACKEND

  const [searchTermTrader, setSearchTermTrader] = useState([]);
  const [filteredDataTrader, setFilteredDataTrader] = useState([]);
  const [traderData, setTraderData] = useState([])
  const [tradeData, setTradeData] = useState([])
  // const [subSchange,setSubChange]=useState('')

  const [sideNavChoose, setSideNavChoose] = useState('Trade List')

  const handleSideNav = (e) => {
    // e.preventDefault()
    console.log(e)
    setSideNavChoose(e)
  }

  useEffect(() => {
    axios.get(`${BACKEND}userDash/fetchTrader`, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => { if (res.data.message == 'ok') { setTraderData(res.data.data); setFilteredDataTrader(res.data.data); console.log(res.data.data, "-55") } })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }, [])

  const [stockData, setStockData] = useState([])
  const [optionData, setOptionData] = useState([])

  useEffect(() => {
    axios.get(`${BACKEND}userDash/fetchTrades`, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => { if (res.data.message == 'ok') { setTradeData(res.data.data); console.log(res.data.data, "trades") } })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }, [])

  useEffect(() => {
    setStockData(tradeData.flatMap(({ username, stocks }) => stocks.map(stock => ({ ...stock, username }))))
    setOptionData(tradeData.flatMap(({ username, options }) => options.map(option => ({ ...option, username }))))
  }, [tradeData])

  const handleSearchTrader = (e) => {
    const input = e.target.value;
    setSearchTermTrader(input);

    const filtered = traderData.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });
    setFilteredDataTrader(filtered);
    console.log("filtered Trader", filteredDataTrader)
  };

  const [currentPageTrader, setCurrentPageTrader] = useState(1);
  const itemsPerPage = 10;



  useEffect(() => {
    setCurrentPageTrader(1)
    console.log('fileterd ', filteredDataTrader)
  }, [filteredDataTrader]);

  const startIndexTrader = (currentPageTrader - 1) * itemsPerPage;
  const endIndexTrader = startIndexTrader + itemsPerPage;

  const itemsToDisplayDataTrader = filteredDataTrader.slice(startIndexTrader, endIndexTrader);

  const handlePageChangeTrader = (page) => {
    if (page >= 1 && page <= Math.ceil(filteredDataTrader.length / itemsPerPage)) {
      setCurrentPageTrader(page);
    }
  };

  const handleSubscribe = async (subTo) => {
    axios.post(`${BACKEND}userDash/subscribe`, { subTo }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => { if (res.data.message == 'ok') { 
        // toast.success('Subscribed');
      setModalTitle('Subscription Successful');
      setModalBodyContent('You have successfully subscribed!');
      setShowModal(true);
      // setTimeout(() => { window.location.reload(true) }, 2000) 
    } })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }

  const handleUnSubscribe = async (subTo) => {
    axios.post(`${BACKEND}userDash/unsubscribe`, { subTo }, {
      headers: {
        Authorization: Cookies.get('Authorization'),
        typeUser: Cookies.get('typeUser'),
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    })
      .then(res => { if (res.data.message == 'ok') { 
        //  toast.success('Unsubscribed');
      setModalTitle('You have Unsubscribed');
      setModalBodyContent('You have successfully unsubscribed!');
      setShowModal(true);
      // setTimeout(() => { window.location.reload(true) }, 2000)
     } })
      .catch(err => { console.log(err); toast.error('Some Error Occured') })
  }

  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [cross, setCross] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 768);
      setCross(screenWidth < 768)
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [searchTermStock, setSearchTermStock] = useState('');
  const [filteredDataStock, setFilteredDataStock] = useState(stockData);

  const [searchTermOption, setSearchTermOption] = useState('');
  const [filteredDataOption, setFilteredDataOption] = useState(optionData);

  const handleSearchStock = (e) => {
    const input = e.target.value;
    setSearchTermStock(input);

    const filtered = stockData.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });

    console.log(stockData)


    setFilteredDataStock(filtered);
  };

  const handleSearchOption = (e) => {
    const input = e.target.value;
    setSearchTermOption(input);
    console.log(input)

    const filtered = optionData.filter((item) => {
      const regex = new RegExp(input, 'i');
      for (const key in item) {
        if (item.hasOwnProperty(key) && regex.test(item[key])) {
          return true;
        }
      }
      return false;
    });
    console.log(filtered)
    setFilteredDataOption(filtered);
  };

  const[unsubshow,setUnsubshow]=useState(false)
  const[subshow,setSubshow]=useState(false)
  const [selectedUsername, setSelectedUsername] = useState(null);
  const[listchoice,setListchoice]=useState('S')

  const [showModal, setShowModal] = useState(false);
  const [showModalun, setShowModalun] = useState(false);
  const [showModalsu, setShowModalsu] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBodyContent, setModalBodyContent] = useState('');
 

  const handleModalClose = () => {
    setShowModal(false);
    setTimeout(() => { window.location.reload(true) }, 2000)
  }



  return (
    <div style={{ backgroundColor: '#E3E2F8', overflow: 'hidden' }}>

<CustomModal
        show={showModal}
        handleClose={handleModalClose}
        title={modalTitle}
        bodyContent={modalBodyContent}
       
      />

      <IoReorderThree size={42}  style={{ top:'50px', position:'fixed', zIndex: '2'}} onClick={() => setIsSmallScreen(false)} />




      {/* <aside style={{position: 'fixed', overflowY: 'auto', minHeight:'100vh' }} className=" main-sidebar  sidebar-dark-primary elevation-4"> */}
      <aside style={{ position: 'fixed', top: '0px', overflowY: 'auto', minHeight: '100vh', zIndex: '2',maxWidth:'268px' }} className={`sidebar-dark-primary elevation-4 ${!isSmallScreen ? '' : 'd-none'}`}>

<a  className="brand-link" style={{ height: '55px' }}>
        </a> 
        {/* <div className="logo" style={{ height:'55px'}}>
        <Link to="/">
          <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent"}}
            className='my-2'
          />
        </Link>
      </div> */}
        <div className={`d-flex m-2 ${isSmallScreen == false ? '' : 'd-none'}`} style={{ color: 'white' }} onClick={() => setIsSmallScreen(true)}>
          <RxCrossCircled className={`ms-auto ${cross ? '' : 'd-none'}`} />
        </div>


        <div className="sidebar">
          <div className="form-inline">
            <div className="input-group my-4" data-widget="sidebar-search">
              <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
              <div className="input-group-append ">
                <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"  />
                </button>
              </div>
            </div>
          </div>
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar col-12 flex-column" data-widget="treeview" role="menu" data-accordion="false">
              <li className="nav-header col-12">AITS User Dashboard</li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Trade List' style={{ color: 'white'}} className="nav-link col-12 btn btn-link text-start" onClick={(e) => handleSideNav(e.target.value)} >
                  <FaListUl style={{ color: 'white' }} className='me-2' />Trade List
                </button>
                <ul className="  fs-6 ms-2"  data-accordion="false">
              <li className="nav-item col-12">
                <button value='Trade List' style={{ color: 'white', backgroundColor: sideNavChoose === 'Trade List' && listchoice === 'S'?'#494E53':'' }} className="nav-link col-12 btn btn-link text-start" onClick={(e) => {handleSideNav(e.target.value);setListchoice('S')}} >
                 Stock
                </button>
              </li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Trade List' style={{ color: 'white', backgroundColor:  sideNavChoose === 'Trade List' && listchoice === 'O'?'#494E53':'' }} onClick={(e) => {handleSideNav(e.target.value);setListchoice('O')}} className="nav-link fs-6 text-start col-12 btn btn-link">
               Option
                </button>
              </li>
          </ul>
        
              </li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Traders Available' style={{ color: 'white', backgroundColor: sideNavChoose === 'Traders Available'?'#494E53':'' }} onClick={(e) => handleSideNav(e.target.value)} className="nav-link text-start col-12 btn btn-link">
                  <BsPeopleFill style={{ color: 'white' }} className='me-2' /> Traders Available
                </button>
              </li>
              <li className="nav-item col-12 d-flex flex-wrap">
                <button value='Subscribed To' style={{ color: 'white', backgroundColor:  sideNavChoose === 'Subscribed To'?'#494E53':'' }} onClick={(e) => handleSideNav(e.target.value)} className="nav-link text-start col-12 btn btn-link">
                  <TiTick style={{ color: 'white' }} className='me-2' /> Subscribed To
                </button>
              </li>

            </ul>
          </nav>
        </div>
      </aside>

      <div style={{ paddingTop: '100px', paddingBottom: '150px', height: '100%', minHeight: '80vh' }} className='responsive-side-nav-other'>
        <h1 className='d-flex justify-content-center'>User Dashboard</h1>


        {/* <div className='row col-12 col-sm-8 mx-auto mt-4 d-flex '>
        <Card style={{ backgroundColor: 'white' }} className='  d-flex rounded-4'>

          <Card.Body className="d-flex flex-column" style={{}}>
                <div className='row col-12 mt-2 d-flex justify-content-between pb-2' style={{ borderBottom: '1px solid white', display: 'flex', flexWrap: 'wrap' }}>

                  <div>
      <h2>Trade List</h2>
      {tradeData.map((trade, index) => (
        <div key={index} className="trade-item row">
          <div className="options col-lg-12  col-sm-10 col-12 text-center text-sm-start">
            {trade.options.map((option, optionIndex) => (
        
              <Accordion >
              <Accordion.Item eventKey={option.ticker+trade.username}>
                <Accordion.Header>Option - {option.ticker} - {trade.username}</Accordion.Header>
                <Accordion.Body>
                <div key={optionIndex} className="option-item p-2 my-4 rounded-3" style={{backgroundColor:'whitesmoke'}}>
                <p>Ticker: {option.ticker}</p>
                <p>Direction: {option.direction}</p>
                <p>Price at Indication: {option.priceAtIndication}</p>
                <p>Strike Date: {option.strikeDate}</p>
                <p>Strike Price: {option.strikePrice}</p>
                <p>Time: {option.time}</p>
              </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            ))}
          </div>
          <div className="stocks col-lg-12 col-sm-10 col-12 text-center text-sm-start">
            {trade.stocks.map((stock, stockIndex) => (
          
              <Accordion >
              <Accordion.Item eventKey={stock.ticker+trade.username}>
                <Accordion.Header>Stock - {stock.ticker} - {trade.username}</Accordion.Header>
                <Accordion.Body>
                <div key={stockIndex} className="stock-item p-2 my-4 rounded-3" style={{backgroundColor:'whitesmoke'}}>
                <p>Ticker: {stock.ticker}</p>
                <p>Direction: {stock.direction}</p>
                <p>Price at Indication: {stock.priceAtIndication}</p>
                <p>Time: {stock.time}</p>
              </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            ))}
          </div>
        </div>
      ))}
                </div>

                </div>
      
          </Card.Body>
        </Card>
      </div> */}

        {
          sideNavChoose === 'Trade List' && listchoice === 'S' ?
            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>
              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Stocks</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermStock} onChange={handleSearchStock} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw" style={{color:'#343A40'}} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Ticker</th>
                        <th>Trader</th>
                        <th>Direction</th>
                        <th>Price at Indication</th>
                        <th>Sell At</th>
                        <th>Stop Loss</th>
                        <th>Performance (in USD)</th>
                        <th>Performance (%)</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataStock.length === 0 ? stockData.length ===0?<h5 className='my-2'>No Records</h5>:
                       stockData.map((stock, stockIndex) => ({ index: stockIndex, date: new Date(stock.time), ...stock }))
                       .sort((a, b) => b.date - a.date)
                       .map(({ stockIndex, date, ...stock }) =>(
                        <tr key={stockIndex}>
                          <td>
                            {stock.ticker}
                          </td>
                          <td>
                            {stock.username}
                          </td>
                          <td>
                            {stock.direction}
                          </td>
                          <td>{stock.priceAtIndication} $</td>
                          <td>{stock.sellAt} $</td>
                          <td>{stock.closeAt} $</td>
                          {
                              stock.performance === 'Profit'?
                              <>
                              <td>{stock.performanceDollar} $</td>
                              <td>{stock.performancePercentage.toFixed(2)} %</td>
                              </>:''
                              }
                              {
                                stock.performance === "Loss"?
                              <>
                              <td>{-stock.performanceDollar} $</td>
                              <td>{-stock.performancePercentage.toFixed(2)} %</td>
                              </>:''
                            }
                                                      {
                              stock.performance === 'TBD'?
                              <>
                              <td>TBD</td>
                              <td>TBD</td>
                              </>:''
                              }
                          <td>
                            {stock.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {stock.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                          </td>
                        </tr>
                      )) :
                        filteredDataStock.map((stock, stockIndex) => ({ index: stockIndex, date: new Date(stock.time), ...stock }))
                        .sort((a, b) => b.date - a.date)
                        .map(({ stockIndex, date, ...stock }) =>(
                          // stock?.stocks?.map(x=>(
                            <tr key={stockIndex}>
                            <td>
                              {stock.ticker}
                            </td>
                            <td>
                              {stock.username}
                            </td>
                            <td>
                              {stock.direction}
                            </td>
                            <td>{stock.priceAtIndication} $</td>
                            <td>{stock.sellAt} $</td>
                            <td>{stock.closeAt} $</td>
                            {
                              stock.performance === 'Profit'?
                              <>
                              <td>{stock.performanceDollar} $</td>
                              <td>{stock.performancePercentage.toFixed(2)} %</td>
                              </>:''
                              }
                              {
                                stock.performance === "Loss"?
                              <>
                              <td>{-stock.performanceDollar} $</td>
                              <td>{-stock.performancePercentage.toFixed(2)} %</td>
                              </>:''
                            }
                                                      {
                              stock.performance === 'TBD'?
                              <>
                              <td>TBD</td>
                              <td>TBD</td>
                              </>:''
                              }
                          
                            <td>
                              {stock.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {stock.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                            </td>
                          </tr>
                        ))}


                    </tbody>
                  </table>
                </div>
              </div>
              </div>:<></>}

              {
          sideNavChoose === 'Trade List' && listchoice === 'O' ?
          <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>
              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Options</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermOption} onChange={handleSearchOption} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw" style={{color:'#343A40'}} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Ticker</th>
                        <th>Trader</th>
                        <th>Strike Price</th>
                        <th>Strike Date</th>
                        <th>Direction</th>
                        <th>Price at Indication</th>
                        <th>Sell At</th>
                        <th>Stop Loss</th>
                        <th>Performance (in USD)</th>
                        <th>Performance (%)</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataOption.length === 0 ? optionData.length ===0?<h5 className='my-2'>No Records</h5>: 
                      optionData.map((option, optionIndex) => ({ index: optionIndex, date: new Date(option.time), ...option }))
                      .sort((a, b) => b.date - a.date)
                      .map(({ optionIndex, date, ...option }) =>(
                        // option.options?.map((x=>
                        <tr key={optionIndex}>
                          <td>
                            {/* <img src="dist/img/default-150x150.png" alt="Product 1" className="img-circle img-size-32 mr-2" /> */}
                            {option.ticker}
                          </td>
                          <td>{option.username}</td>
                          <td>{option.strikePrice} $</td>
                          <td>{option.strikeDate.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} </td>
                          <td>
                            {option.direction}
                          </td>
                          <td>{option.priceAtIndication} $</td>
                          <td>{option.sellAt} $</td>
                          <td>{option.closeAt} $</td>
                          {
                              option.performance === 'Profit'?
                              <>
                              <td>{option.performanceDollar} $</td>
                              <td>{option.performancePercentage.toFixed(2)} %</td>
                              </>:''
                              }
                              {
                                option.performance === "Loss"?
                              <>
                              <td>{-option.performanceDollar} $</td>
                              <td>{-option.performancePercentage.toFixed(2)} %</td>
                              </>:''
                            }
                                                      {
                              option.performance === 'TBD'?
                              <>
                              <td>TBD</td>
                              <td>TBD</td>
                              </>:''
                              }
                          <td>
                            {option.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {option.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                          </td>
                        </tr>
                      )) :
                        filteredDataOption.map((option, optionIndex) => ({ index: optionIndex, date: new Date(option.time), ...option }))
                        .sort((a, b) => b.date - a.date)
                        .map(({ optionIndex, date, ...option }) =>(
                          // option.options?.map((x=>
                          <tr key={optionIndex}>
                          <td>
                            {/* <img src="dist/img/default-150x150.png" alt="Product 1" className="img-circle img-size-32 mr-2" /> */}
                            {option.ticker}
                          </td>
                          <td>{option.username}</td>
                          <td>{option.strikePrice} $</td>
                          <td>{option.strikeDate.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} </td>
                          <td>
                            {option.direction}
                          </td>
                          <td>{option.priceAtIndication} $</td>
                          <td>{option.sellAt} $</td>
                          <td>{option.closeAt} $</td>
                          {
                              option.performance === 'Profit'?
                              <>
                              <td>{option.performanceDollar} $</td>
                              <td>{option.performancePercentage.toFixed(2)} %</td>
                              </>:''
                              }
                              {
                                option.performance === "Loss"?
                              <>
                              <td>{-option.performanceDollar} $</td>
                              <td>{-option.performancePercentage.toFixed(2)} %</td>
                              </>:''
                            }
                                                      {
                              option.performance === 'TBD'?
                              <>
                              <td>TBD</td>
                              <td>TBD</td>
                              </>:''
                              }
                          <td>
                            {option.time.match(/^(\d{4}-\d{2}-\d{2})T/)[1]} {option.time.match(/T(\d{2}:\d{2}:\d{2}.\d{3})Z$/)[1]}
                          </td>
                        </tr>
                        ))}


                    </tbody>
                  </table>
                </div>
              </div>
            </div> : <></>
        }


        {
          sideNavChoose === 'Traders Available' ?
            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>

              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Traders Available</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermTrader}
                      onChange={handleSearchTrader} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw" style={{color:'#343A40'}} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataTrader.length === 0 ? itemsToDisplayDataTrader.length ===0?<h5 className='my-2'>No Records</h5>:  itemsToDisplayDataTrader.map((x, Index) => (
                        <tr key={Index}>
                          <td>
                            {x.username}
                          </td>
                          <td>{x.name} </td>

                          {x.isSubscribed === true ?
                             <td>
                             <button value={x.username} onClick={() => {setUnsubshow(true);setShowModalun(true);setSelectedUsername(x.username)}} type="button" class="btn btn-secondary btn-sm">Unsubscribe</button>
                          {
                           unsubshow && selectedUsername === x.username ? 
                        //    <tr>
                        //    <td>
                        //    <button value={x.username} onClick={(subTo) => handleUnSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                        //    </td>
                        //    <td>
                        //    <button value={x.username} onClick={() => setUnsubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                        //    </td>
                        //  </tr>
                        <Modal show={showModalun}  onHide={() => setShowModalun(false)}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to unsubscribe?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowModalun(false)}>
                            No
                          </Button>
                          <Button variant="secondary" value={x.username} onClick={(subTo) => {handleUnSubscribe(subTo.target.value);setShowModalun(false);setTimeout(() => { window.location.reload(true) }, 2000)}}>
                            Yes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                         :<></>
                          }
                          
                           </td> :
                            <td>
                            <button value={x.username} onClick={() => {setShowModalsu(true);setSubshow(true);setSelectedUsername(x.username)}} type="button" class="btn btn-secondary btn-sm">Subscribe</button>
                         {
                          subshow && selectedUsername === x.username ?
                        //   <tr>
                        //        <td>
                        //   <button value={x.username} onClick={(subTo) => handleSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                        //   </td>
                        //   <td>
                        //   <button value={x.username} onClick={() => setSubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                        //   </td>
                       
                        // </tr>
                        <Modal show={showModalsu}  onHide={() => setShowModalsu(false)}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                         Proceed to Subscribe
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowModalsu(false)}>
                            No
                          </Button>
                          <Button variant="secondary" value={x.username} onClick={(subTo) => {handleSubscribe(subTo.target.value);setShowModalsu(false); setTimeout(() => { window.location.reload(true) }, 2000)}}>
                            Yes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                        :<></>
                         }
                         
                          </td>
                          }

                        </tr>
                      )) :
                      filteredDataTrader.length ===0?<h5 className='my-2'>No Records</h5>: filteredDataTrader.map((x, Index) => (
                          // stock?.stocks?.map(x=>(
                          <tr key={Index}>
                            <td>
                              {x.username}
                            </td>
                            <td>{x.name}</td>
                            {x.isSubscribed === true ?
                              <td>
                                <button value={x.username} onClick={() => {setUnsubshow(true);setShowModalun(true);setSelectedUsername(x.username)}} type="button" class="btn btn-secondary btn-sm">Unsubscribe</button>
                             {
                              unsubshow && selectedUsername === x.username ?
                           //    <tr>
                        //    <td>
                        //    <button value={x.username} onClick={(subTo) => handleUnSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                        //    </td>
                        //    <td>
                        //    <button value={x.username} onClick={() => setUnsubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                        //    </td>
                        //  </tr>
                        <Modal show={showModalun}  onHide={() => setShowModalun(false)}>
                        <Modal.Header closeButton>
                          <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          Are you sure you want to unsubscribe?
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => setShowModalun(false)}>
                            No
                          </Button>
                          <Button variant="secondary" value={x.username} onClick={(subTo) => {handleUnSubscribe(subTo.target.value);setShowModalun(false);setTimeout(() => { window.location.reload(true) }, 2000)}}>
                            Yes
                          </Button>
                        </Modal.Footer>
                      </Modal>
                            :<></>
                             }
                             
                              </td> :
                               <td>
                               <button value={x.username} onClick={() => {setSubshow(true);setShowModalsu(true);setSelectedUsername(x.username)}} type="button" class="btn btn-primary btn-sm">Subscribe</button>
                            {
                             subshow && selectedUsername === x.username ?
                          //    <tr>
                          //       <td>
                          //    <button value={x.username} onClick={(subTo) => handleSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                          //    </td>
                          //    <td>
                          //    <button value={x.username} onClick={() => setSubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                          //    </td>
                           
                          //  </tr>
                          <Modal show={showModalsu}  onHide={() => setShowModalsu(false)}>
                          <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                           Proceed to Subscribe
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowModalsu(false)}>
                              No
                            </Button>
                            <Button variant="secondary" value={x.username} onClick={(subTo) => {handleSubscribe(subTo.target.value);setShowModalsu(false);setTimeout(() => { window.location.reload(true) }, 2000)}}>
                              Yes
                            </Button>
                          </Modal.Footer>
                        </Modal>
                           :<></>
                            }
                            
                             </td>
                            }
                          </tr>
                        ))}


                    </tbody>
                  </table>
                </div>
              </div>

            </div> : <></>
        }
        {
          sideNavChoose === 'Subscribed To' ?
            <div className='row col-12 col-sm-11 mx-auto mt-4 d-flex '>

              <div className="card">
                <div className="card-header border-0 d-flex justify-content-between flex-wrap">
                  <h3 className="card-title mt-2 me-auto">Subscribed To</h3>
                  <div className="input-group col-12 col-sm-6 " >
                    <input className="form-control " type="search" placeholder="Search" aria-label="Search" value={searchTermTrader}
                      onChange={handleSearchTrader} />
                    <div className="input-group-append ">
                      <button className="btn btn-sidebar">
                      <i className="fas fa-search fa-fw" style={{color:'#343A40'}} />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body table-responsive p-0">
                  <table className="table table-striped table-valign-middle">
                    <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>

                      {filteredDataTrader.length === 0 ? itemsToDisplayDataTrader.filter(x => x.isSubscribed === true).length ===0 ?<h5 className='my-2'>No Records</h5>: itemsToDisplayDataTrader.filter(x => x.isSubscribed === true).map((x, Index) => (
                        <tr key={Index}>
                          <td>
                            {x.username }
                          </td>
                          <td>{x.name}</td>
                          <td>
                                <button value={x.username} onClick={() => {setShowModalun(true);setUnsubshow(true);setSelectedUsername(x.username)}} type="button" class="btn btn-secondary btn-sm">Unsubscribe</button>
                             {
                              unsubshow && selectedUsername === x.username ?
                            //   <tr>
                            //          <td>
                            //   <button value={x.username} onClick={(subTo) => handleUnSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                            //   </td>
                            //   <td>
                            //   <button value={x.username} onClick={() => setUnsubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                            //   </td>
                         
                            // </tr>
                            <Modal show={showModalun}  onHide={() => setShowModalun(false)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to unsubscribe?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={() => setShowModalun(false)}>
                                No
                              </Button>
                              <Button variant="secondary" value={x.username} onClick={(subTo) => {handleUnSubscribe(subTo.target.value);setShowModalun(false);setTimeout(() => { window.location.reload(true) }, 2000)}}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        
                            :<></>
                             }
                             
                              </td> 
                        </tr>
                      )) :
                      filteredDataTrader.filter(x => x.isSubscribed === true).length ===0 ?<h5 className='my-2'>No Records</h5>: filteredDataTrader.filter(x => x.isSubscribed === true).map((x, Index) => (

                          <tr key={Index}>
                            <td>
                              {x.username }
                            </td>
                            <td>{x.name}</td>

                            <td>
                                <button value={x.username} onClick={() => {setShowModalun(true);setUnsubshow(true);setSelectedUsername(x.username)}} type="button" class="btn btn-secondary btn-sm">Unsubscribe</button>
                             {
                              unsubshow && selectedUsername === x.username ?
                            //   <tr>
                            //      <td>
                            //   <button value={x.username} onClick={(subTo) => handleUnSubscribe(subTo.target.value)} type="button" class="btn btn-secondary btn-sm">Yes</button>
                            //   </td>
                            //   <td>
                            //   <button value={x.username} onClick={() => setUnsubshow(false)} type="button" class="btn btn-secondary btn-sm">No</button>
                            //   </td>
                             
                            // </tr>
                            <Modal show={showModalun}  onHide={() => setShowModalun(false)}>
                            <Modal.Header closeButton>
                              <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to unsubscribe?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button variant="secondary" onClick={() => setShowModalun(false)}>
                                No
                              </Button>
                              <Button variant="secondary" value={x.username} onClick={(subTo) => {handleUnSubscribe(subTo.target.value);setShowModalun(false);setTimeout(() => { window.location.reload(true) }, 2000)}}>
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                            :<></>
                             }
                             
                              </td> 

                          </tr>

                        ))}


                    </tbody>
                  </table>
                </div>
              </div>

            </div> : <></>
        }



      </div>
    </div>
  )
}

export default Section1UserDash