import Home from "./Pages/Home";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import About from "./Pages/About";
import Stratergist from "./Pages/Stratergist";
import Footer from "./components/Footer";
import Optimizer from "./Pages/Optimizer";
import Community from "./Pages/Community";
import EarlyAccess from "./Pages/EarlyAccess";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import TraderDash from "./Pages/TraderDash";
import UserDash from "./Pages/UserDash";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "./components/Navbar";
import ForgetPassword from "./Pages/ForgetPassword";
import ScrollToTop from '../src/components/ScrollToTop'

function App() {
  return (
    <div className="wrapper">
      <Router>
        {/* <Navbar /> */}
        <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/stratergy" element={<Stratergist/>}/>
          <Route path="/optimizer" element={<Optimizer/>}/>
          <Route path="/community" element={<Community/>}/>
          <Route path="/early" element={<EarlyAccess/>}/>
          <Route path="/signin" element={<SignIn/>}/>
          <Route path="/signup" element={<SignUp/>}/>
          <Route path="/forgetPassword" element={<ForgetPassword/>}/>
          <Route path="/traderDash" element={<TraderDash/>}/>
          <Route path="/userDash" element={<UserDash/>}/>
        </Routes>
        {/* <Footer/> */}
      </Router>
    </div>
  );
}

export default App;
