import React from 'react'
import Footer from "../components/Footer";
import Navbar from '../components/Navbar';
import Section1ForgetPassword from '../components/ForgetPassword/Section1ForgetPassword';

const ForgetPassword = () => {
  return (
    <div style={{ height: "100vh" }}>
    <Navbar/>
    <Section1ForgetPassword/>
    <Footer/>
   
 </div>
  )
}

export default ForgetPassword