import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../Images/navbar-logo.png'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import {ThreeDots} from 'react-loader-spinner';

const Section1ForgetPassword = () => {

    const BACKEND = process.env.REACT_APP_BACKEND

    const navigate = useNavigate();

    const[username,setUsername]=useState('')
    const[OTP,setOTP]=useState('')
    const[loading, setLoading] = useState(false);
    const[loading2, setLoading2] = useState(false);
    const[loading3, setLoading3] = useState(false);
    const[showForm,setShowFrom]=useState(false)
    const[showP,setShowP]=useState(false)
    const[password,setPassword]=useState('')
    const[showMainform,setShowmainform]=useState(true)

    const handleOTP=(e)=>{
        e.preventDefault()
        setLoading(true)

        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(username)) {
          toast.error("Invalid Email Format");
          setLoading(false);
          console.log(username)
          return;
        }

        axios.post(`${BACKEND}forgetpassword`,{username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'ok'){ setLoading(false);setShowFrom(true);setShowmainform(false);console.log(res.data);toast.success('Verification Code Sent')}
          else if(res.data.error){ setLoading(false);console.log(res.data.error);toast.error(res.data.error)}})
          .catch(err=>{ setLoading(false);console.log(err);toast.error('Some Error Occured')})
    }

    const handleOTPFP=(e)=>{
        e.preventDefault()
        setLoading2(true)

        if (OTP === '') {
          toast.error("Verification Code Required");
          setLoading2(false);
          console.log(username)
          return;
        }

        axios.post(`${BACKEND}forgetpassword/otp`,{OTP,username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'ok'){ setLoading2(false);setShowFrom(false);setShowP(true);console.log(res.data);toast.success('Password Change Allowed')}
          else if(res.data.error){ setLoading2(false);console.log(res.data.error);toast.error(res.data.error);setShowFrom(false);setShowmainform(true)}})
          .catch(err=>{ setLoading2(false);console.log(err);toast.error('Some Error Occured');setShowFrom(false);setShowmainform(true)})
    }

    const handlePass=(e)=>{
        e.preventDefault()
        setLoading3(true)

        if (password.length < 8) {
            console.log("pass")
            toast.error("Password must be at least 8 characters long");
            setLoading3(false);
            return;
          }
          const passRegex = /\s{2,}/;
          if (passRegex.test(password)) {
            toast.error("Invalid Password Format, 2 consecutive white spaces");
            setLoading3(false);
            return;
          }

        axios.post(`${BACKEND}forgetpassword/pass`,{password,username},{
            headers: {
              'Content-type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
          }).then(res=> {if(res.data == 'ok'){ setLoading3(false);setShowP(false);console.log(res.data);toast.success('Password Changed Successfully');setTimeout(() => { navigate('/') }, 2000)}
          else if(res.data.error){ setLoading3(false);console.log(res.data.error);toast.error(res.data.error);setShowP(false)}})
          .catch(err=>{ setLoading3(false);console.log(err);toast.error('Some Error Occured');setShowP(false)})
    }

  return (
    <div style={{ backgroundColor: '#E3E2F8', overflow:'hidden' }}>
     
     <div className='' style={{paddingTop:'100px',paddingBottom:'150px',   height:'80vh'}} >
  <div className='d-flex justify-content-center col-12'  >
    <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent"}}
            className='text-center '
          />
    </div>

    {
      showMainform ?
      <div className='d-flex justify-content-center col-12'  >
      <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1 " style={{width:'40%',minWidth:'300px', backgroundColor:'white',  border:'1px solid lightgrey'}}>
<div className="row">
  <h3 className='text-center mb-3'>Forgot Password</h3>

  <div className="col-sm-12">
    <label htmlFor="email" className="mb-1">
      Email <span className="text-danger">*</span>
    </label>
    <div className="input-group mb-3">
      <input
        type="email"
        className="form-control"
        placeholder="Email"
        id="emailFreelancer"
        
        aria-label="email"
        value={username}
        onChange={(e)=>setUsername(e.target.value)}
      />
    </div>
  </div>
  <div className="col-12">
    <button
      type="submit"
      className="btn  mt-3 d-block col-sm-6 mx-auto col-12"
      style={{backgroundColor:'#5E17EB', color:'white'}}
       onClick={(e) => handleOTP(e)}
    >
      Send Verification Code
    </button>
  </div>
  <div className='form-group d-flex justify-content-center mt-4'>
 {loading ? (
    <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
   
  ) : (
    ''
  )}
  </div>



</div>
</form>
</div>:<></>
    }


{
    showForm ?
    <div className='d-flex justify-content-center col-12'  >
        <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1 " style={{width:'40%',minWidth:'300px', backgroundColor:'white',  border:'1px solid lightgrey'}}>
  <div className="row">

    <div className="col-sm-12">
      <label htmlFor="email" className="mb-1">
      Verification Code <span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Verification Code"
          id="emailFreelancer"
          
          aria-label="Verification Code"
          value={OTP}
          onChange={(e)=>setOTP(e.target.value)}
        />
      </div>
    </div>
    <div className="col-12">
      <button
        type="submit"
        className="btn  mt-3 d-block col-sm-6 mx-auto col-12"
        style={{backgroundColor:'#5E17EB', color:'white'}}
         onClick={(e) => handleOTPFP(e)}
      >
        Submit Verification Code
      </button>
    </div>
    <div className='form-group d-flex justify-content-center mt-4'>
   {loading2 ? (
      <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
     
    ) : (
      ''
    )}
    </div>



</div>
</form>
</div>:<></>
}

{
    showP ?
    <div className='d-flex justify-content-center col-12'  >
        <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1 " style={{width:'40%',minWidth:'300px', backgroundColor:'white',  border:'1px solid lightgrey'}}>
  <div className="row">

    <div className="col-sm-12">
      <label htmlFor="email" className="mb-1">
        New Password <span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="password"
          className="form-control"
          placeholder="password"
          id="emailFreelancer"
          
          aria-label="password"
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
        />
      </div>
    </div>
    <div className="col-12">
      <button
        type="submit"
        className="btn  mt-3 d-block col-sm-6 mx-auto col-12"
        style={{backgroundColor:'#5E17EB', color:'white'}}
         onClick={(e) => handlePass(e)}
      >
        Change Password
      </button>
    </div>
    <div className='form-group d-flex justify-content-center mt-4'>
   {loading3 ? (
      <ThreeDots type="Oval" position="top-center" color="black" height={50} width={50} />
     
    ) : (
      ''
    )}
    </div>



</div>
</form>
</div>:<></>
}




</div>
<ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />

        </div>
  )
}

export default Section1ForgetPassword