import React,{useState,useEffect} from 'react'
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import {BsGoogle} from 'react-icons/bs'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ThreeDots} from 'react-loader-spinner';
import axios from 'axios'
import Logo from '../../Images/navbar-logo.png'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';


const Section1SignIn = () => {

  const BACKEND = process.env.REACT_APP_BACKEND

  const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const[loading, setLoading] = useState(false);
    const [showLogin,setShowLogin]=useState(true)
    const[togLog,setTogLog]=useState(false)

    const handleSubmit=(e)=>{
      e.preventDefault()
      console.log("handle submit")
      setLoading(true)

      axios.post(`${BACKEND}login`,{username,password},{
        headers: {
          'Content-type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
      })
      // .then(res => console.log(res.data.st))
      .then(res => {if(res.data.st=='ok'){console.log(res.data.token);Cookies.set('Authorization', res.data.token);Cookies.set('typeUser', res.data.typeUser);Cookies.set('nameUser', res.data.name);setLoading(false);toast.success("Sign In Successful");setTimeout(() => { navigate('/') }, 2000)}
      else{console.log(res.data);setLoading(false);toast.error(res.data.error)}})
      .catch(err => {console.log(err,"l");setLoading(false);toast.error("Some Error Occurred")})
    }

    // const handleSubmitPro=(e)=>{
    //   e.preventDefault()
    //   console.log("handle submit")
    //   setLoading(true)

    //   axios.get(`${BACKEND}protected`,{
    //     headers: {
    //       Authorization: Cookies.get('Authorization'),
    //       typeUser:Cookies.get('typeUser'),
    //       'Content-type': 'application/json',
    //         'Accept': 'application/json',
    //         'Access-Control-Allow-Origin': '*'
    //     },
    //   })
    //   .then(res => console.log(res.data))
    // }

  return (
    <div style={{ backgroundColor: '#E3E2F8', overflow:'hidden' }}>
  <div className='' style={{paddingTop:'100px',paddingBottom:'150px',   minHeight:'80vh'}} >
  <div className='d-flex justify-content-center col-12'  >
    <img
            src={Logo}
            alt=""
            width={120}
            height={40}
            style={{ backgroundColor: "transparent"}}
            className='text-center '
          />
    </div>
    <div className='d-flex justify-content-center col-12 pb-1'  >
        <form action="#" className="mt-4 register-form rounded-3 p-3 mx-1 " style={{width:'40%',minWidth:'270px', backgroundColor:'white',  border:'1px solid lightgrey'}}>
  <div className="row">
    <h3 className='text-center mb-3'>Sign In</h3>

    {/* <div className="col-sm-12">
        <label htmlFor="email" className="mb-1">
          Google Login / Sign up
        </label>
        <div className="input-group mb-3">
         <button className='btn btn-dark col-12 '
        //   onClick={(e)=>{handleGoogle(e)}}
           >Google <BsGoogle style={{color:'white'}} className='ms-2'/></button>
        </div>
      </div> */}

    <div className="col-sm-12">
      <label htmlFor="email" className="mb-1">
        Email<span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="email"
          className="form-control"
          placeholder="Email"
          id="emailFreelancer"
          
          aria-label="email"
          value={username}
          onChange={(e)=>setUsername(e.target.value)}
        />
      </div>
    </div>
    <div className="col-sm-12">
      <label htmlFor="password" className="mb-1">
        Password<span className="text-danger">*</span>
      </label>
      <div className="input-group mb-3">
        <input
          type="password"
          className="form-control"
          placeholder="Password"
          id="passwordFreelancer"
  
          aria-label="Password"
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
        />
      </div>
    </div>

    {/* <div className="form-check">
  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked/>
  <label className="form-check-label" for="flexRadioDefault1">
    Users
  </label>
</div>
<div className="form-check">
  <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
  <label className="form-check-label" for="flexRadioDefault2">
    Traders
  </label>
</div> */}

<div className="col-sm-12">
        <div className="input-group mb-3">
        <Link to='/signup' style={{ textDecoration:'none'}}><Nav.Link href="#link" className='btn link' style={{ color:'#5E17EB' }}>Don't have an account? Sign up</Nav.Link></Link>        
        </div>
      </div>

      <div className="col-sm-12">
        <div className="input-group mb-3">
        <Link to='/forgetPassword' style={{ textDecoration:'none'}}><Nav.Link href="#link" className='btn link' style={{ color:'#5E17EB' }}>Forgot Password ?</Nav.Link></Link>        
        </div>
      </div>

    {
      showLogin === true ?
      <>
    <div className="col-12">
      <button
        type="submit"
        className="btn  mt-3 d-block col-sm-6 mx-auto col-12"
        style={{backgroundColor:'#5E17EB', color:'white'}}
         onClick={(e) => handleSubmit(e)}
      >
        Login
      </button>
    </div>
     <div className="col-sm-12">
     {/* <div className="input-group mt-2 mb-3">
     <Link to='/forgetpassword' style={{ textDecoration:'none'}}><Nav.Link href="#link" className='btn link' style={{color:'#5E17EB' }}>Forgot Password</Nav.Link></Link>        
     </div> */}
   </div>
   </>
    :
    <div className="col-12">
    <button type="button"
    //  onClick={(e)=> logoutToggle(e)} 
     className="btn btn-outline-danger  mt-3 d-block w-100">Logout</button>
    </div>
    }
    {togLog ?
    <div className='form-group mt-3 d-flex justify-content-center'>
      <button className='btn btn-danger mx-1 '
    //    onClick={()=> logout()}
       >Logout</button>
      <button className='btn btn-secondary mx-1 ' 
    //   onClick={(e) => logoutToggle(e)}
      >No</button>
    </div> : ''
  }
  </div>
</form>
</div>
</div>
<ToastContainer position="top-center" autoClose={5000} hideProgressBar={true} />
    </div>
  )
}

export default Section1SignIn