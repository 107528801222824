import React from 'react'
import Footer from "../components/Footer";
import Section1UserDash from '../components/UserDash/Section1UserDash';
import Navbar from '../components/Navbar';


const UserDash = () => {

 

  return (
    <div style={{ height: "100vh" }}>
    <Navbar />
    <Section1UserDash/>
    <Footer/>
   
 </div>
  )
}

export default UserDash